<template>
  <div>
    <b-row class="d-flex justify-content-between">
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1 style="font-size: 2rem" class="text-colorBlue font-weight-bolder">
            {{ this.stats.total_teachers }}
          </h1>
          <div>
            <h4
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Count of Teachers
            </h4>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/dashboard/enroll.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 ml-0 ml-md-1 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1
            style="font-size: 2rem"
            class="text-colorOrange font-weight-bolder"
          >
            {{ this.stats.total_students }}
          </h1>
          <div>
            <h4
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Count of Students
            </h4>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/uniHead/dashboard/teacher.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 ml-0 ml-md-1 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1
            style="font-size: 2rem"
            class="text-colorYellow font-weight-bolder"
          >
            {{ this.stats.total_classes }}
          </h1>

          <div>
            <h5
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Count of Classes/Programs
            </h5>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/dashboard/class.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 ml-0 ml-md-1"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1 style="font-size: 2rem" class="text-colorRed font-weight-bolder">
            {{ this.stats.total_subjects }}
          </h1>
          <div>
            <h4
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Count of Subjects
            </h4>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/dashboard/assignments.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-between mt-2 h-50">
      
      <b-col
        md="6"
        class="p-0 m-0 bg-colorWhite w-100 ml-1"
        style="border-radius: 20px"
      >
        <div class="pt-2 px-2 w-100">
          <div class="w-100">
            <h3 class="font-weight-bolder text-colorBlack">Free Trail</h3>
            <p class="font-weight-bold text-colorGray">
              You are using a free trail please update your plan
            </p>
          </div>
          <div class="my-bg">
            <div class="" style="padding: 20px 0px 440px 20px">
              <h3 class="font-weight-bolder text-colorBlack">
                End Up : 04/05/2024
              </h3>
            </div>
            <div class="text-center pb-2">
              <b-link>
                <b-button variant="colorBlue px-5">Subscribe Now</b-button>
              </b-link>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      stats: {}
    };
  },
  methods: {
    ...mapActions({
      getInstituteStats: "appData/getInstituteStats",
    }),
    async getDashboardData(){
      try {
        const res = await this.getInstituteStats();
        this.stats = res.data;
      } catch (error) {
        this.displayError(error);
      }
    }
  },
  async mounted(){
    await this.getDashboardData();
  },
};
</script>

<style>
.my-bg {
  background: url("~@/assets/images/icons/uniHead/dashboard/subscription.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
</style>
